<template>
	<v-container
			fluid
	>
		<v-row justify="center">
			<v-col cols="12">
				<v-card>
					<v-card-text>
						<v-data-table
								:loading="loading"
								:headers="headers"
								:items="items"
								:server-items-length="totalItems"
								item-key="id"
								:search="search"
								:options.sync="options"
								:footer-props="{'items-per-page-options':[10,25,50]}"
						>
							<template v-slot:top>
								<v-row no-gutters>
									<v-col cols="12">
										<v-text-field no-gutters clearable v-model="search" label="Search" class="ps-4 pt-6 pr-4"></v-text-field>
									</v-col>
								</v-row>
							</template>
							<template v-slot:[`item.image_url`]="{ item }">
								<v-img class="ma-2" :src="item.image_url" max-height="72" max-width="72" />
							</template>
							<template v-slot:[`item.user`]="{ item }">
								<span class="text-no-wrap">{{ item.user_custom_id }}</span>
							</template>
							<template v-slot:[`item.read_percentage`]="{ item }">
								<span class="text-no-wrap">{{ Math.round(item.read / item.created * 100) }}%</span>
							</template>
							<template v-slot:[`item.closed`]="{ item }">
								<span class="text-no-wrap">{{ Math.round(item.closed / item.read * 100) }}%</span>
							</template>
							<template v-slot:[`item.cta`]="{ item }">
								<span class="text-no-wrap">{{ Math.round(item.cta / item.read * 100) }}%</span>
							</template>
							<template v-slot:[`item.creation_date`]="{ item }">
								<span class="text-no-wrap">{{ dayjs(item.creation_date).format('YYYY-MM-DD') }}</span>
							</template>
							<template v-slot:[`item.expired_at`]="{ item }">
								<span class="text-no-wrap">{{ dayjs(item.expired_at).format('YYYY-MM-DD') }}</span>
							</template>
							<template v-slot:[`item.group_meta.type`]="{ item }">
								<span>{{ item.group_meta.type.replaceAll(`_`, ` `) }}</span>
							</template>
						</v-data-table>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	export default {
		data: () => ({
			loading: false,
			search: '',
			options: {
				sortDesc: [true],
				sortBy: [`creation_date`],
			},
			headers: [
				{ text: `Group type`, value: `group_meta.type`, width: `150px` },
				{ text: ``, value: `image_url`, sortable: false },
				{ text: `Title`, value: `title` },
				{ text: `Header`, value: `header` },
				{ text: `Message`, value: `body_text` },
				{ text: `Created`, value: `created` },
				{ text: `Read`, value: `read` },
				{ text: `Read %`, value: `read_percentage` },
				{ text: `CTA % of read`, value: `cta` },
				{ text: `Closed % of read`, value: `closed` },
				{ text: `Created`, value: `creation_date` },
				{ text: `Expired`, value: `expired_at` },
			],
			totalItems: 0,
			items: [],
		}),
		methods: {
			refreshContent () {
				this.loading = true
				this.$httpInt.get(`/v2/app/group-messages`, { params: this.$paginationParams(this) })
					.then(res => {
						this.items = res.data.result
						this.totalItems = res.data.pagination.total
					})
					.finally(() => this.loading = false)
			},
		},
		name: `MessagesGroupMessages`,
		watch: {
			search: {
				handler (value) {
					if (value && value.length < 4) return
					this.$debounce(() => this.refreshContent(), 300)
				},
				deep: true,
			},
			options: {
				handler () {
					this.refreshContent()
				},
				deep: true,
			},
		},
	}
</script>
