var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-text',[_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.totalItems,"item-key":"id","search":_vm.search,"options":_vm.options,"footer-props":{'items-per-page-options':[10,25,50]}},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"ps-4 pt-6 pr-4",attrs:{"no-gutters":"","clearable":"","label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]},proxy:true},{key:"item.image_url",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"ma-2",attrs:{"src":item.image_url,"max-height":"72","max-width":"72"}})]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.user_custom_id))])]}},{key:"item.read_percentage",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(Math.round(item.read / item.created * 100))+"%")])]}},{key:"item.closed",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(Math.round(item.closed / item.read * 100))+"%")])]}},{key:"item.cta",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(Math.round(item.cta / item.read * 100))+"%")])]}},{key:"item.creation_date",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.dayjs(item.creation_date).format('YYYY-MM-DD')))])]}},{key:"item.expired_at",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.dayjs(item.expired_at).format('YYYY-MM-DD')))])]}},{key:"item.group_meta.type",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.group_meta.type.replaceAll("_", " ")))])]}}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }